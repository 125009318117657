import(/* webpackMode: "eager", webpackExports: ["Analytics"] */ "/home/runner/work/nextwww/nextwww/node_modules/@vercel/analytics/dist/next/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/nextwww/nextwww/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/nextwww/nextwww/node_modules/next-intl/dist/esm/navigation/shared/LegacyBaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/nextwww/nextwww/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/nextwww/nextwww/node_modules/next/font/local/target.css?{\"path\":\"src/app/fonts.ts\",\"import\":\"\",\"arguments\":[{\"src\":\"../assets/fonts/SilkaMono/silkamono-regular-webfont.woff2\",\"display\":\"swap\",\"variable\":\"--font-silka\",\"weight\":\"bold\"}],\"variableName\":\"silka\"}");
;
import(/* webpackMode: "eager" */ "/home/runner/work/nextwww/nextwww/node_modules/next/font/local/target.css?{\"path\":\"src/app/fonts.ts\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../assets/fonts/Adelphi/AdelphiPEVF-latin.woff2\",\"weight\":\"125 950\",\"style\":\"oblique 0deg 20deg\"}],\"display\":\"swap\",\"variable\":\"--font-adelphi\",\"declarations\":[{\"prop\":\"unicode-range\",\"value\":\"U+0000-00FF\"},{\"prop\":\"font-stretch\",\"value\":\"75% 125%\"},{\"prop\":\"font-variation-settings\",\"value\":\"\\\"opsz\\\" 6\"}]}],\"variableName\":\"adelphi\"}");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/home/runner/work/nextwww/nextwww/node_modules/sonner/dist/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["Providers"] */ "/home/runner/work/nextwww/nextwww/src/app/[locale]/providers.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PreloadResources"] */ "/home/runner/work/nextwww/nextwww/src/app/preload-resources.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Analytics"] */ "/home/runner/work/nextwww/nextwww/src/components/Analytics/Analytics.tsx");
